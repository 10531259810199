import * as turf from '@turf/turf'

const multiPolygon = turf.multiPolygon(
    [
        [
          [
            [
              9.59754,
              46.942024
            ],
            [
              9.597562,
              46.941935
            ],
            [
              9.597888,
              46.941992
            ],
            [
              9.598178,
              46.942014
            ],
            [
              9.598544,
              46.941944
            ],
            [
              9.598791,
              46.941959
            ],
            [
              9.599058,
              46.941886
            ],
            [
              9.599091,
              46.941823
            ],
            [
              9.598941,
              46.941715
            ],
            [
              9.598881,
              46.941621
            ],
            [
              9.598926,
              46.941554
            ],
            [
              9.599181,
              46.941467
            ],
            [
              9.599443,
              46.941281
            ],
            [
              9.599677,
              46.941096
            ],
            [
              9.599904,
              46.940973
            ],
            [
              9.600065,
              46.940814
            ],
            [
              9.600317,
              46.940672
            ],
            [
              9.60055,
              46.940585
            ],
            [
              9.600707,
              46.940498
            ],
            [
              9.600748,
              46.940446
            ],
            [
              9.600692,
              46.940253
            ],
            [
              9.600672,
              46.940118
            ],
            [
              9.600754,
              46.939982
            ],
            [
              9.600894,
              46.939891
            ],
            [
              9.60147,
              46.939681
            ],
            [
              9.601637,
              46.939549
            ],
            [
              9.601807,
              46.939385
            ],
            [
              9.60192,
              46.939249
            ],
            [
              9.602053,
              46.939154
            ],
            [
              9.602233,
              46.939104
            ],
            [
              9.603118,
              46.938927
            ],
            [
              9.603731,
              46.938837
            ],
            [
              9.604219,
              46.938465
            ],
            [
              9.60442,
              46.938388
            ],
            [
              9.604565,
              46.938056
            ],
            [
              9.604468,
              46.937655
            ],
            [
              9.604506,
              46.937607
            ],
            [
              9.604718,
              46.937398
            ],
            [
              9.604644,
              46.937252
            ],
            [
              9.604515,
              46.937127
            ],
            [
              9.604275,
              46.936904
            ],
            [
              9.604247,
              46.936753
            ],
            [
              9.604745,
              46.936383
            ],
            [
              9.604949,
              46.936052
            ],
            [
              9.605091,
              46.935917
            ],
            [
              9.605292,
              46.935806
            ],
            [
              9.605487,
              46.935651
            ],
            [
              9.605863,
              46.935457
            ],
            [
              9.606104,
              46.935279
            ],
            [
              9.606293,
              46.93512
            ],
            [
              9.606647,
              46.934929
            ],
            [
              9.606828,
              46.934388
            ],
            [
              9.606569,
              46.934106
            ],
            [
              9.60622,
              46.933659
            ],
            [
              9.605717,
              46.933237
            ],
            [
              9.605587,
              46.932858
            ],
            [
              9.604888,
              46.932325
            ],
            [
              9.60476,
              46.931989
            ],
            [
              9.604327,
              46.931754
            ],
            [
              9.604317,
              46.931639
            ],
            [
              9.60386,
              46.931396
            ],
            [
              9.603367,
              46.93121
            ],
            [
              9.603268,
              46.930894
            ],
            [
              9.602791,
              46.930719
            ],
            [
              9.602362,
              46.930772
            ],
            [
              9.602168,
              46.930801
            ],
            [
              9.602014,
              46.930871
            ],
            [
              9.601527,
              46.93057
            ],
            [
              9.601221,
              46.930411
            ],
            [
              9.601077,
              46.930161
            ],
            [
              9.600862,
              46.929968
            ],
            [
              9.600748,
              46.929858
            ],
            [
              9.601074,
              46.929666
            ],
            [
              9.601026,
              46.929346
            ],
            [
              9.601152,
              46.929021
            ],
            [
              9.601071,
              46.928587
            ],
            [
              9.601199,
              46.928369
            ],
            [
              9.601363,
              46.928055
            ],
            [
              9.601401,
              46.927847
            ],
            [
              9.601479,
              46.927683
            ],
            [
              9.601626,
              46.927461
            ],
            [
              9.601641,
              46.927325
            ],
            [
              9.601743,
              46.927003
            ],
            [
              9.601958,
              46.926839
            ],
            [
              9.602213,
              46.926615
            ],
            [
              9.602559,
              46.926495
            ],
            [
              9.602695,
              46.92636
            ],
            [
              9.602728,
              46.926217
            ],
            [
              9.602444,
              46.925873
            ],
            [
              9.602081,
              46.925637
            ],
            [
              9.601295,
              46.925749
            ],
            [
              9.600762,
              46.925543
            ],
            [
              9.600204,
              46.925641
            ],
            [
              9.599518,
              46.92529
            ],
            [
              9.599441,
              46.925142
            ],
            [
              9.599084,
              46.924969
            ],
            [
              9.598818,
              46.924734
            ],
            [
              9.598381,
              46.924284
            ],
            [
              9.598138,
              46.923907
            ],
            [
              9.598189,
              46.923831
            ],
            [
              9.597686,
              46.923789
            ],
            [
              9.597626,
              46.923698
            ],
            [
              9.59767,
              46.923579
            ],
            [
              9.597456,
              46.923542
            ],
            [
              9.597187,
              46.923401
            ],
            [
              9.596796,
              46.922967
            ],
            [
              9.59679,
              46.922864
            ],
            [
              9.596637,
              46.922764
            ],
            [
              9.596328,
              46.92248
            ],
            [
              9.596348,
              46.922405
            ],
            [
              9.596194,
              46.922282
            ],
            [
              9.596115,
              46.921979
            ],
            [
              9.595894,
              46.921864
            ],
            [
              9.595658,
              46.921605
            ],
            [
              9.595478,
              46.92148
            ],
            [
              9.585319,
              46.920913
            ],
            [
              9.581894,
              46.920433
            ],
            [
              9.5812,
              46.920247
            ],
            [
              9.578947,
              46.919981
            ],
            [
              9.576917,
              46.919853
            ],
            [
              9.575435,
              46.919529
            ],
            [
              9.575335,
              46.91974
            ],
            [
              9.572905,
              46.919768
            ],
            [
              9.568438,
              46.919835
            ],
            [
              9.567512,
              46.919649
            ],
            [
              9.566538,
              46.919396
            ],
            [
              9.56564,
              46.919127
            ],
            [
              9.564614,
              46.918733
            ],
            [
              9.556064,
              46.919613
            ],
            [
              9.558125,
              46.926561
            ],
            [
              9.558281,
              46.927235
            ],
            [
              9.558354,
              46.927915
            ],
            [
              9.558341,
              46.928597
            ],
            [
              9.558244,
              46.929276
            ],
            [
              9.558063,
              46.929947
            ],
            [
              9.557799,
              46.930604
            ],
            [
              9.557727,
              46.930753
            ],
            [
              9.557651,
              46.930901
            ],
            [
              9.557499,
              46.93117
            ],
            [
              9.557333,
              46.931436
            ],
            [
              9.557153,
              46.931697
            ],
            [
              9.55696,
              46.931954
            ],
            [
              9.556753,
              46.932206
            ],
            [
              9.556533,
              46.932452
            ],
            [
              9.556362,
              46.932631
            ],
            [
              9.556184,
              46.932807
            ],
            [
              9.555999,
              46.93298
            ],
            [
              9.555807,
              46.933149
            ],
            [
              9.555609,
              46.933315
            ],
            [
              9.555404,
              46.933476
            ],
            [
              9.55528,
              46.933571
            ],
            [
              9.555154,
              46.933664
            ],
            [
              9.555025,
              46.933755
            ],
            [
              9.554894,
              46.933845
            ],
            [
              9.554761,
              46.933934
            ],
            [
              9.554626,
              46.934021
            ],
            [
              9.549736,
              46.937132
            ],
            [
              9.549495,
              46.937288
            ],
            [
              9.549258,
              46.937447
            ],
            [
              9.549025,
              46.937609
            ],
            [
              9.548796,
              46.937774
            ],
            [
              9.548571,
              46.937941
            ],
            [
              9.548331,
              46.938126
            ],
            [
              9.54812,
              46.938295
            ],
            [
              9.547914,
              46.938466
            ],
            [
              9.547712,
              46.93864
            ],
            [
              9.547514,
              46.938816
            ],
            [
              9.54732,
              46.938994
            ],
            [
              9.547131,
              46.939174
            ],
            [
              9.546796,
              46.939511
            ],
            [
              9.546477,
              46.939854
            ],
            [
              9.546173,
              46.940204
            ],
            [
              9.545886,
              46.94056
            ],
            [
              9.545615,
              46.940923
            ],
            [
              9.54536,
              46.941291
            ],
            [
              9.54521,
              46.941524
            ],
            [
              9.545065,
              46.941759
            ],
            [
              9.544928,
              46.941996
            ],
            [
              9.544798,
              46.942235
            ],
            [
              9.544674,
              46.942475
            ],
            [
              9.544557,
              46.942717
            ],
            [
              9.544407,
              46.943054
            ],
            [
              9.54427,
              46.943394
            ],
            [
              9.544147,
              46.943735
            ],
            [
              9.544038,
              46.944079
            ],
            [
              9.543941,
              46.944425
            ],
            [
              9.543859,
              46.944772
            ],
            [
              9.543753,
              46.945348
            ],
            [
              9.543685,
              46.945926
            ],
            [
              9.543654,
              46.946505
            ],
            [
              9.543661,
              46.947085
            ],
            [
              9.543705,
              46.947664
            ],
            [
              9.543787,
              46.948241
            ],
            [
              9.543839,
              46.948514
            ],
            [
              9.543899,
              46.948785
            ],
            [
              9.543968,
              46.949055
            ],
            [
              9.544045,
              46.949325
            ],
            [
              9.544331,
              46.950273
            ],
            [
              9.545924,
              46.955559
            ],
            [
              9.547517,
              46.960845
            ],
            [
              9.549905,
              46.968775
            ],
            [
              9.550513,
              46.968024
            ],
            [
              9.550929,
              46.96746
            ],
            [
              9.551424,
              46.96674
            ],
            [
              9.552112,
              46.966159
            ],
            [
              9.55236,
              46.964957
            ],
            [
              9.553998,
              46.965138
            ],
            [
              9.554753,
              46.961921
            ],
            [
              9.555352,
              46.95901
            ],
            [
              9.555639,
              46.957569
            ],
            [
              9.556303,
              46.956844
            ],
            [
              9.558261,
              46.954776
            ],
            [
              9.564392,
              46.948274
            ],
            [
              9.567208,
              46.944955
            ],
            [
              9.567363,
              46.94419
            ],
            [
              9.568756,
              46.942983
            ],
            [
              9.568929,
              46.942845
            ],
            [
              9.569057,
              46.942749
            ],
            [
              9.569272,
              46.942604
            ],
            [
              9.569397,
              46.942526
            ],
            [
              9.569604,
              46.942406
            ],
            [
              9.570645,
              46.941856
            ],
            [
              9.570985,
              46.941697
            ],
            [
              9.571154,
              46.941612
            ],
            [
              9.571658,
              46.941317
            ],
            [
              9.571814,
              46.941254
            ],
            [
              9.571907,
              46.941192
            ],
            [
              9.572067,
              46.941109
            ],
            [
              9.572189,
              46.941057
            ],
            [
              9.57253,
              46.940849
            ],
            [
              9.573198,
              46.940495
            ],
            [
              9.573329,
              46.940413
            ],
            [
              9.57348,
              46.940346
            ],
            [
              9.573768,
              46.940193
            ],
            [
              9.574016,
              46.940082
            ],
            [
              9.574283,
              46.940016
            ],
            [
              9.574568,
              46.939925
            ],
            [
              9.574656,
              46.939893
            ],
            [
              9.575175,
              46.93968
            ],
            [
              9.57531,
              46.939606
            ],
            [
              9.575865,
              46.939403
            ],
            [
              9.576015,
              46.939371
            ],
            [
              9.576191,
              46.939346
            ],
            [
              9.576359,
              46.939315
            ],
            [
              9.576467,
              46.939278
            ],
            [
              9.57673,
              46.939224
            ],
            [
              9.577084,
              46.939171
            ],
            [
              9.577386,
              46.939091
            ],
            [
              9.57759,
              46.939045
            ],
            [
              9.577821,
              46.938981
            ],
            [
              9.578165,
              46.938858
            ],
            [
              9.578251,
              46.938824
            ],
            [
              9.578757,
              46.938711
            ],
            [
              9.578918,
              46.938642
            ],
            [
              9.579253,
              46.938537
            ],
            [
              9.579526,
              46.938492
            ],
            [
              9.579623,
              46.938448
            ],
            [
              9.579675,
              46.938405
            ],
            [
              9.579913,
              46.938312
            ],
            [
              9.580106,
              46.938296
            ],
            [
              9.580477,
              46.938195
            ],
            [
              9.580693,
              46.938076
            ],
            [
              9.58089,
              46.937951
            ],
            [
              9.581053,
              46.937815
            ],
            [
              9.581211,
              46.937631
            ],
            [
              9.581388,
              46.937522
            ],
            [
              9.581558,
              46.937464
            ],
            [
              9.581894,
              46.937444
            ],
            [
              9.58218,
              46.937439
            ],
            [
              9.582287,
              46.937414
            ],
            [
              9.582517,
              46.937349
            ],
            [
              9.582731,
              46.937274
            ],
            [
              9.58302,
              46.937051
            ],
            [
              9.583229,
              46.936931
            ],
            [
              9.583692,
              46.936677
            ],
            [
              9.584116,
              46.936473
            ],
            [
              9.584411,
              46.936452
            ],
            [
              9.584875,
              46.936355
            ],
            [
              9.585125,
              46.936264
            ],
            [
              9.585295,
              46.936245
            ],
            [
              9.585443,
              46.936244
            ],
            [
              9.58553,
              46.936249
            ],
            [
              9.585318,
              46.936794
            ],
            [
              9.585796,
              46.937136
            ],
            [
              9.586403,
              46.937506
            ],
            [
              9.586848,
              46.938004
            ],
            [
              9.587903,
              46.937636
            ],
            [
              9.587534,
              46.938249
            ],
            [
              9.587679,
              46.938407
            ],
            [
              9.588382,
              46.938568
            ],
            [
              9.588869,
              46.938684
            ],
            [
              9.589412,
              46.938472
            ],
            [
              9.589984,
              46.93894
            ],
            [
              9.590253,
              46.938908
            ],
            [
              9.59095,
              46.938786
            ],
            [
              9.591478,
              46.939318
            ],
            [
              9.592024,
              46.93916
            ],
            [
              9.592659,
              46.939308
            ],
            [
              9.593906,
              46.939842
            ],
            [
              9.594684,
              46.940259
            ],
            [
              9.595291,
              46.940649
            ],
            [
              9.59634,
              46.941234
            ],
            [
              9.59754,
              46.942024
            ]
          ]
        ]
      ]
)



const masked = turf.mask(multiPolygon);


export default masked;
